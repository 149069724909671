import React from 'react';
import PropTypes from 'prop-types';

import Popover2 from '@dolstaff/shared/es/Popover2';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { OnlineHeaderStep } from 'shared/OnlineHeaderStep';

import { Container, Section as SectonOrg } from '../../shared/Blocks';
import Image from '../../shared/Image';
import MainLayout from '../../shared/MainLayout';
import Title from '../../shared/MainLayout/Title';
// import RipplePlayIcon from '../../shared/RipplePlayIcon';
import {
  FixedBody,
  FixedFooter,
  Main
} from '../../shared/Marketing/Online/shared';
import { ImagePanel, NormalText } from '../../shared/Marketing/Shared';
// import cl from 'classnames';
import MySEO from '../../shared/MySEO';
import getAssetLink from '../../utils/getAssetLink';
import HeaderSchedulePage from '../../shared/Header/HeaderSchedulePage';

const Section = styled(SectonOrg)`
  background-color: #fbfbfb;

  @media screen and (min-width: 1024px) {
    min-height: 700px;
    display: flex;
    justify-content: stretch;
  }
`;

// const TooltipShowOnce = styled.div`
//   position: absolute;
//   z-index: 2;
//   top: calc(50% - 38px + 4px);
//   left: -135px;
//   background-color: #2d3742;
//   border-radius: 10px;
//   border: 1px solid #2d3742;
//   color: white;
//   padding: 1em;
//   width: 154px;
//   text-align: center;
//   font-size: 13px;
//   display: none;
//   &.open {
//     display: block;
//   }
//   &:after {
//     content: ' ';
//     position: absolute;
//     top: 50%;
//     left: 100%; /* To the right of the tooltip */
//     margin-top: -8px;
//     border-width: 8px;
//     border-style: solid;
//     border-color: transparent transparent transparent #2d3742;
//   }
// `;

const AudioWrp = styled.div`
  position: relative;

  .audioContent {
    position: absolute;
    bottom: 0;
    left: 0;

    audio {
      outline: none;
      height: 49px;
    }
  }
`;

const Bullet = styled.ul`
  padding-left: 1em;
  li {
    margin-bottom: 1em;
  }
`;

const img06 = {
  handle: '2k4zaHQcmb0GVTvU1bQR',
  width: 1884,
  height: 1340
};

const audio = {
  handle: 'wHB0dJBURcOidzSSi1RK'
};

class IndexPage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      playClicked: false
    };
  }

  render() {
    const { playClicked } = this.state;
    return (
      <MainLayout
        showFooterRegister={false}
        noMenu
        noFooter
        hideSideCTA
        className="Step4Main"
        noHeader
        renderCustomHeader={() => (
          <HeaderSchedulePage hideMobileBottomNav noMenu />
        )}
      >
        <MySEO title="Bước 4: Khóa học online, trải nghiệm như offline" />
        <Main>
          <OnlineHeaderStep activeStep={3} />

          <FixedBody>
            <Section selfish>
              <Container>
                <ImagePanel style={{ marginTop: '0', marginBottom: '0' }}>
                  <div className="panelBody">
                    <div className="RespHeader">
                      <div className="cirle">
                        <div className="label">
                          <b>4</b>/5
                        </div>
                        <div className="circleProgress">
                          <div className="circleCommon Left ">
                            <span
                              className="progressPercent"
                              style={{ transform: 'rotate(110deg)' }}
                            />
                          </div>
                          <div className="circleCommon Right ">
                            <span className="progressPercent" />
                          </div>
                        </div>
                      </div>
                      <h6>Sửa bài</h6>
                      <span>Next: Luyện tập</span>
                    </div>
                    <Title className="small">BƯỚC 04: SỬA BÀI VIẾT</Title>
                    <NormalText>
                      <Bullet>
                        <li>
                          Trong suốt quá trình học, học viên sẽ làm bài và nộp
                          bài trực tiếp qua hệ thống app của DOL.
                        </li>
                        <li>
                          Bài làm của học viên sẽ{' '}
                          <b>
                            được TA chấm cực kì cụ thể, không chỉ chỉ ra lỗi mà
                            còn giải thích chi tiết (bằng text hoặc file audio)
                            lỗi đó
                          </b>{' '}
                          là gì và chỉ ra cách khắc phục như thế nào, cũng như
                          làm sao để &quot;nâng tầm&quot; bài viết của mình lên
                          một level mới.
                        </li>
                        {/* <li>
                          Bài làm của học viên sẽ được TA chấm cực kì cụ thể,
                          không chỉ chỉ ra lỗi mà còn giải thích chi tiết (bằng
                          text hoặc file audio) lỗi đó là gì và chỉ ra cách khắc
                          phục như thế nào, cũng như làm sao để &quot;nâng
                          tầm&quot; bài viết của mình lên một level mới.
                        </li> */}
                      </Bullet>
                    </NormalText>
                  </div>
                  <div className="panelImage">
                    <AudioWrp>
                      <Image image={img06} maxWidth={500} />
                      <div className="audioContent">
                        <Popover2
                          position="top center"
                          arrow
                          arrowStyle={{
                            backgroundColor: '#2D3742'
                          }}
                          contentStyle={{
                            backgroundColor: '#2D3742',
                            borderRadius: '4px',
                            border: '1px solid #2D3742',
                            width: '100px',
                            color: 'white',
                            padding: '1em'
                          }}
                          trigger={() => (
                            <audio
                              controls
                              onPlay={() => {
                                this.setState({
                                  playClicked: true
                                });
                              }}
                            >
                              <source
                                src={getAssetLink({ item: audio })}
                                type="audio/mp4"
                              />
                              <track kind="captions" />
                            </audio>
                          )}
                        >
                          {() => <div>Nghe thử Voice</div>}
                        </Popover2>
                        {!playClicked && (
                          <div className="overlayButton">
                            <div className="ripple" />
                            <i className="icon">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 10 14"
                                fill="none"
                              >
                                <path
                                  d="M9.636 6.445L1.698.382a.473.473 0 00-.543-.03.623.623 0 00-.28.534v12.126c0 .223.107.429.28.534a.478.478 0 00.543-.03l7.938-6.063a.635.635 0 00.235-.504.635.635 0 00-.235-.504z"
                                  fill="#fff"
                                />
                              </svg>
                            </i>
                          </div>
                        )}
                      </div>
                    </AudioWrp>
                  </div>
                </ImagePanel>
              </Container>
            </Section>
          </FixedBody>

          <FixedFooter>
            <div className="wrapper-footer">
              <Link to="/online/step3" className="btn btnBack">
                Quay lại
              </Link>
              <Link to="/online/step5" className="btn btnNext">
                Tiếp
              </Link>
            </div>
          </FixedFooter>
        </Main>
      </MainLayout>
    );
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    meta: PropTypes.string.isRequired
  }).isRequired
};

export default IndexPage;
